import { configureStore } from '@reduxjs/toolkit';
import noticesReducer from '../reducers/noticesReducer';
import reportsToApproveReducer from '../reducers/reportsToApproveReducer';
import reportsReducer from '../reducers/reportReducer';
import emergencyReducer from '../reducers/emergencyReducer';
import supportRequestsReducer from '../reducers/SupportRequestsReducer';

export default configureStore({
  reducer: {
    notices: noticesReducer,
    reportsToApprove: reportsToApproveReducer,
    reports: reportsReducer,
    emergencyRequests:emergencyReducer,
    supportRequests:supportRequestsReducer
  },
});
