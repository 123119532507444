import { createAction } from '@reduxjs/toolkit';

export const setSupportRequestsCount = createAction(
  'supportRequests/setSupportRequestsCount'
);
export const decrementSupportRequestsCount = createAction(
  'supportRequests/decrementSupportRequestsCount'
);
export const setSupportRequests = createAction(
  'supportRequests/setSupportRequests'
);
export const addNewSupportRequest = createAction(
  'supportRequests/addNewSupportRequest'
);
export const addMoreSupportRequests = createAction(
  'supportRequests/addMoreSupportRequests'
);
export const removeSupportRequest = createAction(
  'supportRequests/removeSupportRequest'
);
