import { createReducer } from '@reduxjs/toolkit';
import {
  setSupportRequestsCount,
  decrementSupportRequestsCount,
  setSupportRequests,
  addNewSupportRequest,
  addMoreSupportRequests,
  removeSupportRequest,
} from '../actions/supportRequests';

const initialState = {
  supportRequestsCount: 0,
  requests: [],
  qIndex: 1,
  moreRequestsExist: true,
};

const supportRequestsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setSupportRequestsCount, (state, action) => {
      state.supportRequestsCount = action.payload;
    })
    .addCase(decrementSupportRequestsCount, (state) => {
      state.supportRequestsCount -= 1;
    })
    .addCase(setSupportRequests, (state, action) => {
      const { requests, qIndex, moreRequestsExist } = action.payload;
      state.requests = requests;
      state.qIndex = qIndex;
      state.moreRequestsExist = moreRequestsExist;
    })
    .addCase(addNewSupportRequest, (state, action) => {
      const requestIndex = state.requests.findIndex(
        (request) => request._id === action.payload._id
      );

      if (requestIndex !== -1) {
        state.requests[requestIndex] = action.payload;
      } else {
        state.requests.push(action.payload);
      }
    })
    .addCase(addMoreSupportRequests, (state, action) => {
      const { requests, qIndex, moreRequestsExist } = action.payload;

      requests.map((request) => {
        const requestIndex = state.requests.findIndex(
          (oldRequest) => oldRequest._id === request._id
        );
        if (requestIndex !== -1) {
          state.requests[requestIndex] = request;
        } else {
          state.requests.push(request);
        }
      });

      state.qIndex = qIndex;
      state.moreRequestsExist = moreRequestsExist;
    })
    .addCase(removeSupportRequest, (state, action) => {
      const requestId = action.payload;
      state.requests = state.requests.filter(
        (request) => request._id !== requestId
      );
      state.supportRequestsCount -= 1;
    });
});

export default supportRequestsReducer;
