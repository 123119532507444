import { createAction } from '@reduxjs/toolkit';

export const setReports = createAction('reports/setReportsData');
export const addNewReport = createAction('reports/addNewReport');
export const addMoreReports = createAction('reports/addMoreReports');

export const addNewParentComment = createAction('reports/addNewParentComment');
export const addMoreParentComments = createAction(
  'reports/addMoreParentComments'
);
export const addNewReplyComment = createAction('reports/addNewReplyComment');
export const setCommentsData = createAction('reports/setCommentsData');
export const setRepliesData = createAction('reports/setRepliesData');
export const addMoreRepliesData = createAction('reports/addMoreRepliesData');
