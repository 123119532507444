import React, { createContext, useContext, useEffect, useState } from 'react';
import { languages } from '../utils/data/utilData';
import tamil from '../utils/data/translations/tamil.json';
import sinhala from '../utils/data/translations/sinhala.json';
import { languageTypes } from '../utils/constants';
import { useLocalStorage } from './useLocalStorage';

const translations = {
  tamil: tamil,
  sinhala: sinhala,
};

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useLocalStorage(
    'language',
    languageTypes.ENGLISH
  );

  // Function to change language
  const changeLanguage = (newLanguage) => {
    const isValidLanguage = languages.some(
      (lang) => lang.value === newLanguage
    );
    if (isValidLanguage) {
      setLanguage(newLanguage);
    } else {
      console.error(`Language '${newLanguage}' is not supported.`);
    }
  };

  const trt = (key, lang = language) => {
    try {
      if (language === languageTypes.ENGLISH) {
        return key;
      }
      const normalizedKey = key.toLowerCase();
      return translations[lang][normalizedKey] || key;
    } catch (error) {
      return key;
    }
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage, trt }}>
      {children}
    </LanguageContext.Provider>
  );
};
