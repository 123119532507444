import { createReducer } from '@reduxjs/toolkit';
import {
  setReportsToApproveCount,
  decrementReportsToApproveCount,
  setReportsToApprove,
  addNewReportToApprove,
  addMoreReportsToApprove,
  removeReportToApprove,
} from '../actions/reportsToApprove';

const initialState = {
  reportsToApproveCount: 0,
  reports: [],
  qIndex: 1,
  moreReportsExist: true,
};

const reportsToApproveReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setReportsToApproveCount, (state, action) => {
      state.reportsToApproveCount = action.payload;
    })
    .addCase(decrementReportsToApproveCount, (state) => {
      state.reportsToApproveCount -= 1;
    })
    .addCase(setReportsToApprove, (state, action) => {
      const { reports, qIndex, moreReportsExist } = action.payload;
      state.reports = reports;
      state.qIndex = qIndex;
      state.moreReportsExist = moreReportsExist;
    })
    .addCase(addNewReportToApprove, (state, action) => {
      const reportIndex = state.reports.findIndex(
        (report) => report._id === action.payload._id
      );

      if (reportIndex !== -1) {
        state.reports[reportIndex] = action.payload;
      } else {
        state.reports.push(action.payload);
      }
    })
    .addCase(addMoreReportsToApprove, (state, action) => {
      const { reports, qIndex, moreReportsExist } = action.payload;

      reports.map((report) => {
        const reportIndex = state.reports.findIndex(
          (oldReport) => oldReport._id === report._id
        );
        if (reportIndex !== -1) {
          state.reports[reportIndex] = report;
        } else {
          state.reports.push(report);
        }
      });

      state.qIndex = qIndex;
      state.moreReportsExist = moreReportsExist;
    })
    .addCase(removeReportToApprove, (state, action) => {
      const reportId = action.payload;
      state.reports = state.reports.filter((report) => report._id !== reportId);
      state.reportsToApproveCount -= 1;
    });
});

export default reportsToApproveReducer;
