import React, { createContext, useContext } from 'react';
import { useLocalStorage } from './useLocalStorage';

// Create UserNavigationContext
const UserNavigationContext = createContext();

// Custom hook for accessing userNavigation context
export const useUserNavigation = () => useContext(UserNavigationContext);

// UserNavigation provider component
export const UserNavigationProvider = ({ children }) => {
  const [userNavigation, setUserNavigation] = useLocalStorage(
    'userNavigation',
    'home'
  );

  // Function to change userNavigation
  const updateUserNavigation = (navigationId) => {
    setUserNavigation(navigationId);
  };

  return (
    <UserNavigationContext.Provider
      value={{ userNavigation, updateUserNavigation }}
    >
      {children}
    </UserNavigationContext.Provider>
  );
};
