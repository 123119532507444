import { createAction } from '@reduxjs/toolkit';

export const setReportsToApproveCount = createAction(
  'reportsToApprove/setReportsToApproveCount'
);
export const decrementReportsToApproveCount = createAction(
  'reportsToApprove/decrementReportsToApproveCount'
);

export const setReportsToApprove = createAction(
  'reportsToApprove/setReportsToApproveData'
);
export const addNewReportToApprove = createAction(
  'reportsToApprove/addNewReportToApprove'
);
export const addMoreReportsToApprove = createAction(
  'reportsToApprove/addMoreReportsToApprove'
);
export const removeReportToApprove = createAction(
  'reportsToApprove/removeReportToApprove'
);
