import { createReducer } from '@reduxjs/toolkit';
import {
  setReports,
  addNewReport,
  addNewParentComment,
  setCommentsData,
  addNewReplyComment,
  setRepliesData,
  addMoreParentComments,
  addMoreRepliesData,
  addMoreReports,
} from '../actions/reports';

const initialState = {
  reports: [],
  qIndex: 1,
  moreReportsExist: true,
};

const reportsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setReports, (state, action) => {
      const { reports, qIndex, moreReportsExist } = action.payload;
      state.reports = reports;
      state.qIndex = qIndex;
      state.moreReportsExist = moreReportsExist;
    })
    .addCase(addNewReport, (state, action) => {
      const reportIndex = state.reports.findIndex(
        (report) => report._id === action.payload._id
      );

      if (reportIndex !== -1) {
        state.reports[reportIndex] = action.payload;
      } else {
        state.reports.push(action.payload);
      }
    })
    .addCase(addMoreReports, (state, action) => {
      const { reports, qIndex, moreReportsExist } = action.payload;

      reports.map((report) => {
        const reportIndex = state.reports.findIndex(
          (oldReport) => oldReport._id === report._id
        );
        if (reportIndex !== -1) {
          state.reports[reportIndex] = report;
        } else {
          state.reports.push(report);
        }
      });
      state.qIndex = qIndex;
      state.moreReportsExist = moreReportsExist;
    })
    .addCase(addNewParentComment, (state, action) => {
      const { reportId, comment, commentsCount } = action.payload;
      const report = state.reports.find((report) => report._id === reportId);
      if (report) {
        if (!report.commentsData) {
          report.commentsData = [];
        }
        report.commentsData.unshift(comment);
        report.commentsCount = commentsCount;
      }
    })
    .addCase(addMoreParentComments, (state, action) => {
      const { reportId, comments, commentsCount, parentCommentsCount, qIndex } =
        action.payload;
      const report = state.reports.find((report) => report._id === reportId);
      if (report) {
        if (!report.commentsData) {
          report.commentsData = [];
        }
        comments.map((comment) => {
          report.commentsData.push(comment);
        });
        if (commentsCount) {
          report.commentsCount = commentsCount;
        }

        if (parentCommentsCount) {
          report.parentCommentsCount = parentCommentsCount;
        }
        if (qIndex) {
          report.qIndex = qIndex;
        }
      }
    })
    .addCase(addNewReplyComment, (state, action) => {
      const { reportId, comment, commentsCount } = action.payload;
      const report = state.reports.find((report) => report._id === reportId);
      if (report) {
        const parentComment = report.commentsData.find(
          (pc) => pc._id === comment.parentComment
        );
        if (parentComment) {
          if (!parentComment.repliesData) {
            parentComment.repliesData = [];
          }
          parentComment.repliesData.push(comment);
          parentComment.repliesCount = parentComment.repliesCount + 1;
          report.commentsCount = commentsCount;
        }
      }
    })
    .addCase(setCommentsData, (state, action) => {
      const { reportId, comments, commentsCount, parentCommentsCount, qIndex } =
        action.payload;
      comments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      const report = state.reports.find((report) => report._id === reportId);
      if (report) {
        report.commentsData = comments;
        report.commentsCount = commentsCount;
        report.parentCommentsCount = parentCommentsCount;
        report.qIndex = qIndex;
      }
    })
    .addCase(setRepliesData, (state, action) => {
      const { comment, replies, repliesCount, qIndex } = action.payload;
      const report = state.reports.find(
        (report) => report._id === comment.reportId
      );
      if (report) {
        const parentComment = report.commentsData.find(
          (pc) => pc._id === comment._id
        );
        if (parentComment) {
          parentComment.repliesData = replies;
          parentComment.repliesCount = repliesCount;
          parentComment.qIndex = qIndex;
        }
      }
    })
    .addCase(addMoreRepliesData, (state, action) => {
      const { comment, replies, repliesCount, qIndex } = action.payload;
      const report = state.reports.find(
        (report) => report._id === comment.reportId
      );
      if (report) {
        const parentComment = report.commentsData.find(
          (pc) => pc._id === comment._id
        );
        if (parentComment) {
          replies.map((reply) => {
            parentComment.repliesData.push(reply);
          });
          parentComment.repliesCount = repliesCount;
          parentComment.qIndex = qIndex;
        }
      }
    });
});

export default reportsReducer;
