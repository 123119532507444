import React, { useEffect, useRef } from 'react';
import useOnlineStatus from '../../hooks/useOnlineStatus';
import { toast } from 'react-toastify';

function OnlineStatusToast() {
  const isOnline = useOnlineStatus();
  const wasOffline = useRef(false);

  useEffect(() => {
    if (isOnline && wasOffline.current) {
      toast.success('You are back online!', {
        position: 'bottom-center',
        theme: 'dark',
        toastId: 'online',
        hideProgressBar: true,
      });
      wasOffline.current = false;
    } else if (!isOnline) {
      toast.error('You are offline!', {
        position: 'bottom-center',
        theme: 'dark',
        toastId: 'online',
        hideProgressBar: true,
      });
      wasOffline.current = true;
    }
  }, [isOnline]);

  return null;
}

export default OnlineStatusToast;
