import React, { createContext, useContext } from 'react';
import { useLocalStorage } from '../useLocalStorage';
import { useNavigate } from 'react-router-dom';
import { tokens } from '../../theme';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage('user', null);

  const navigate = useNavigate();

  const setOTPToken = (OTPtoken) => {
    try {
      if (!OTPtoken) {
        throw new Error('Invalid token');
      }
      localStorage.setItem('otp-token', OTPtoken);
    } catch (error) {
      console.error('Error in verification:', error);
    }
  };

  const setResetPasswordToken = (token) => {
    try {
      if (!token) {
        throw new Error('Invalid token');
      }
      localStorage.setItem('reset-token', token);
    } catch (error) {
      console.error('Error in verification:', error);
    }
  };

  const removeOTPToken = () => {
    try {
      localStorage.removeItem('otp-token');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const setSignIn = (userData, userToken) => {
    try {
      if (!userData || !userToken) {
        throw new Error('Invalid user data or token');
      }

      const { _id, firstName, lastName, role, preferredLanguage } = userData;

      if (!_id || !firstName || !lastName || !role || !preferredLanguage) {
        throw new Error('Incomplete user data');
      }

      setUser(userData);
      localStorage.setItem('auth-token', userToken);
      console.log('Signed in successfully!');
      return true;
    } catch (error) {
      console.error('Error signing in:', error);
      navigate('/signin');
    }
  };

  const setSignOut = () => {
    try {
      setUser(null);
      localStorage.removeItem('user');
      localStorage.removeItem('auth-token');
      navigate('/signin');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const isSignedIn = () => {
    return !!user && !!localStorage.getItem('auth-token');
  };

  const isAdmin = () => {
    return user.role === 'admin';
  };

  const isModerator = () => {
    return user.role === 'moderator';
  };

  const updateAvatar = (avatarUrl) => {
    try {
      if (!user) {
        throw new Error('User not found');
      }

      const updatedUser = {
        ...user,
        avatar: avatarUrl,
      };

      setUser(updatedUser);
    } catch (error) {
      console.error('Error updating avatar:', error);
    }
  };

  const updateName = (firstName, lastName) => {
    try {
      if (!user) {
        throw new Error('User not found');
      }

      const updatedUser = {
        ...user,
        firstName,
        lastName,
      };

      setUser(updatedUser);
    } catch (error) {
      console.error('Error updating name:', error);
    }
  };

  const updateUser = (updatedData) => {
    try {
      if (!user) {
        throw new Error('User not found');
      }

      const updatedUser = {
        ...user,
        ...updatedData,
      };

      setUser(updatedUser);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setSignOut,
        setSignIn,
        isSignedIn,
        setOTPToken,
        removeOTPToken,
        updateAvatar,
        updateName,
        isAdmin,
        isModerator,
        setResetPasswordToken,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
