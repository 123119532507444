// !#####################################################
// ! DO NOT EDIT THIS CONSTANTS
// ! IF YOU MAKE ANY CHANGES, DO THE SAME IN SERVER SITE
// !#####################################################
export const noticeTypes = {
  NEW_REPORT: 'new-report',
  APPRROVED_REPORT: 'approved-report',
  REJECTED_REPORT: 'rejected-report',
  NEW_COMMENT: 'new-comment',
  NEW_REPLY: 'new-reply',
  RESOLVED_REPORT: 'resolved-report',
  NEW_HELP_REQUEST: 'new-help-request',
  NEW_EMERGENCY_REQUEST: 'new-emergency-request',
};

export const userTypes = {
  ADMIN: 'admin',
  MODERATOR: 'moderator',
  REPORTER: 'reporter',
};

export const languageTypes = {
  TAMIL: 'tamil',
  SINHALA: 'sinhala',
  ENGLISH: 'english',
};

export const ADMIN_DASHBOARD_IDS = {
  DASHBOARD: 'dashboard',
  STAKEHOLDERS: 'stakeholders',
  USERS: 'users',
  REPORT: 'report',
  SUPPORT_DATA: 'support-data',
  SUPPORT_PROVIDERS: 'support-providers',
  EMERGENCY_DATA: 'emergency-data',
  FEEDBACK_DATA: 'feedback-data',
  LOCATION_DATA: 'location-data',
};

export const ADMIN_DASHBOARD_URLS = {
  DASHBOARD: '/admin/dashboard',
  STAKEHOLDERS: '/admin/stakeholders',
  USERS: '/admin/users',
  REPORT: '/admin/report',
  SUPPORT_DATA: '/admin/support-data',
  SUPPORT_PROVIDERS: '/admin/support-providers',
  EMERGENCY_DATA: '/admin/emergency-data',
  FEEDBACK_DATA: '/admin/feedback-data',
  LOCATION_DATA: '/admin/location-data',
};

export const MAIN_MENU_IDS = {
  HOME: 'home',
  EMERGENCY: 'emergency',
  HELP_SUPPORT: 'help-support',
  NOTIFICATIONS: 'notifications',
  PROFILE: 'profile',
  APPROVE_REPORTS: 'approve-reports',
  SUPPORT_PROVIDING: 'support-providing',
  SUPPORT_REQUESTS: 'support-requests',
  EMERGENCY_REQUESTS: 'emergency-requests',
  WRITE_REPORT: 'write-report',
  REPORT_PROFILE: 'reports',
};

export const MAIN_MENU_URLS = {
  HOME: '/feed',
  EMERGENCY: '/emergency',
  HELP_SUPPORT: '/help-and-support',
  NOTIFICATIONS: '/notification',
  PROFILE: '/user/profile',
  APPROVE_REPORTS: '/approve-reports',
  SUPPORT_PROVIDING: '/stakeholder',
  SUPPORT_REQUESTS: '/support-requests',
  EMERGENCY_REQUESTS: '/emergency-requests',
  WRITE_REPORT: '/write-report',
  REPORT_PROFILE: '/reports',
};

export const OTHERS_MENU_IDS = {
  SETTINGS: 'settings',
  DASHBOARD: 'dashboard',
};

export const OTHERS_MENU_URLS = {
  SETTINGS: '/settings',
  DASHBOARD: '/admin/dashboard',
};

export const SETTINGS_DASHBOARD_IDS = {
  PUBLIC_PROFILE: 'public-profile',
  PRIVACY_SECURITY: 'privacy-security',
  PREFERENCES: 'preferences',
  FEEDBACK: 'feedback',
};

export const SETTINGS_DASHBOARD_URLS = {
  PUBLIC_PROFILE: '/settings/public-profile',
  PRIVACY_SECURITY: '/settings/privacy',
  PREFERENCES: '/settings/preferences',
  FEEDBACK: '/settings/feedback',
};

export const commentTypes = {
  PARENT: 'parent',
  REPLY: 'reply',
};

export const maxCharacters = {
  REPORT_BODY: 200,
  COMMENT_BODY: 150,
};

export const drawerWidth = '270px';

export const PAGE_URLS = {
  ADMIN: '/admin',
  SETTINGS: '/settings',
  SIGNIN: '/signin',
  SIGNUP: '/signup',
  VERIFY_EMAIL: '/verify-email',
  ADD_EMAIL: '/add-email',
  ADD_MOBILE_NUM: '/add-mobile-number',
  VERIFY_MOBILE_NUM: '/verify-mobile-number',
  FORGOT_PASSWORD: '/forgotpassword',
  HOME: '/home',
  APP_PRIVACY_POLICY: '/app-privacy-policy',
  ACCOUNT_DELETION_REQUEST: '/account-deletion-request',
};

export const PRIMARY_DOMAIN = 'visact.media';

export const errorTexts = {
  otpTokenExpired: 'OTP Token Expired',
  invalidOTP: 'Invalid OTP',
  authTokenExpired: 'Auth Token Expired',
  emailNotVerified: 'Email not verified.',
  mobileNumberNotVerified: 'Mobile number not verified.',
};
