import { createReducer } from '@reduxjs/toolkit';
import {
  addMoreNotifications,
  addNewNotification,
  decrementNotificationCount,
  removeNotification,
  setNotificationCount,
  setNotifications,
} from '../actions/notices';

const initialState = {
  notificationCount: 0,
  notices: [],
  qIndex: 1,
  moreExist: true,
};

const noticesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setNotificationCount, (state, action) => {
      state.notificationCount = action.payload;
    })
    .addCase(decrementNotificationCount, (state) => {
      state.notificationCount -= 1;
    })
    .addCase(setNotifications, (state, action) => {
      const { notices, qIndex, moreExist } = action.payload;
      state.notices = notices;
      state.qIndex = qIndex;
      state.moreExist = moreExist;
    })
    .addCase(addNewNotification, (state, action) => {
      const requestIndex = state.notices.findIndex(
        (request) => request._id === action.payload._id
      );

      if (requestIndex !== -1) {
        state.notices[requestIndex] = action.payload;
      } else {
        state.notices.push(action.payload);
      }
    })
    .addCase(addMoreNotifications, (state, action) => {
      const { notices, qIndex, moreExist } = action.payload;

      notices.map((request) => {
        const requestIndex = state.notices.findIndex(
          (old) => old._id === request._id
        );
        if (requestIndex !== -1) {
          state.notices[requestIndex] = request;
        } else {
          state.notices.push(request);
        }
      });

      state.qIndex = qIndex;
      state.moreExist = moreExist;
    })
    .addCase(removeNotification, (state, action) => {
      const requestId = action.payload;
      state.notices = state.notices.filter(
        (request) => request._id !== requestId
      );
      state.emergencyCount -= 1;
    });
});

export default noticesReducer;
