import { createReducer } from '@reduxjs/toolkit';
import {
  decrementEmergencyCount,
  setEmergencyRequests,
  addNewEmergencyRequest,
  addMoreEmergencyRequests,
  removeEmergencyRequest,
  setEmergencyCount,
} from '../actions/emergencyRequest';

const initialState = {
  emergencyCount: 0,
  requests: [],
  qIndex: 1,
  moreRequestsExist: true,
};

const emergencyRequestsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setEmergencyCount, (state, action) => {
      state.emergencyCount = action.payload;
    })
    .addCase(decrementEmergencyCount, (state) => {
      state.emergencyCount -= 1;
    })
    .addCase(setEmergencyRequests, (state, action) => {
      const { requests, qIndex, moreRequestsExist } = action.payload;
      state.requests = requests;
      state.qIndex = qIndex;
      state.moreRequestsExist = moreRequestsExist;
    })
    .addCase(addNewEmergencyRequest, (state, action) => {
      const requestIndex = state.requests.findIndex(
        (request) => request._id === action.payload._id
      );

      if (requestIndex !== -1) {
        state.requests[requestIndex] = action.payload;
      } else {
        state.requests.push(action.payload);
      }
    })
    .addCase(addMoreEmergencyRequests, (state, action) => {
      const { requests, qIndex, moreRequestsExist } = action.payload;

      requests.map((request) => {
        const requestIndex = state.requests.findIndex(
          (oldRequest) => oldRequest._id === request._id
        );
        if (requestIndex !== -1) {
          state.requests[requestIndex] = request;
        } else {
          state.requests.push(request);
        }
      });

      state.qIndex = qIndex;
      state.moreRequestsExist = moreRequestsExist;
    })
    .addCase(removeEmergencyRequest, (state, action) => {
      const requestId = action.payload;
      state.requests = state.requests.filter(
        (request) => request._id !== requestId
      );
      state.emergencyCount -= 1;
    });
});

export default emergencyRequestsReducer;
