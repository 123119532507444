import { createAction } from '@reduxjs/toolkit';

export const setNotificationCount = createAction(
  'notices/setNotificationCount'
);
export const decrementNotificationCount = createAction(
  'notices/decrementNotificationCount'
);

export const setNotifications = createAction('notices/setNotifications');
export const addNewNotification = createAction('notices/addNewNotification');
export const addMoreNotifications = createAction(
  'notices/addMoreNotifications'
);
export const removeNotification = createAction('notices/removeNotification');
