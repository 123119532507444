import { createAction } from '@reduxjs/toolkit';

export const setEmergencyCount = createAction('emergency/setEmergencyCount');
export const decrementEmergencyCount = createAction(
  'emergency/decrementEmergencyCount'
);

export const setEmergencyRequests = createAction(
  'emergency/setEmergencyRequests'
);
export const addNewEmergencyRequest = createAction(
  'emergency/addNewEmergencyRequest'
);
export const addMoreEmergencyRequests = createAction(
  'emergency/addMoreEmergencyRequests'
);
export const removeEmergencyRequest = createAction(
  'emergency/removeEmergencyRequest'
);
