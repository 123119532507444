import { languageTypes } from '../constants';
export const languages = [
  { value: languageTypes.ENGLISH, title: 'English' },
  { value: languageTypes.TAMIL, title: 'தமிழ்' },
  { value: languageTypes.SINHALA, title: 'සිංහල' },
];

export const userTypes = [
  { value: 'public', title: 'Public' },
  { value: 'go', title: 'Government Organisation' },
  { value: 'ngo', title: 'NGO' },
];
